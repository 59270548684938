<template>
  <b-form class="text-center">
    <div v-for="filterGroup in filterGroups">
      <b-button v-if="filterProperties" v-for="(filter,key) in filterGroup" variant="light" :pressed="filter.enabled" @click="onFilterChanged(filterGroup, key)">
        {{filter.label}}
      </b-button>
      <br/><br/>
    </div>
    <b-input-group v-if="searchProperties">
      <i class="fa fa-search"/>
      <b-input class="form-control" v-model="searchValue" placeholder="Search..."/>
      <b-btn variant="light" @click="searchValue = ''">X</b-btn>
    </b-input-group>
  </b-form>
</template>

<script>
  import * as _ from "lodash";

  export default {
    name: "DataFilter",
    props: ["dataList", "filterProperties", "searchProperties", "storagePrefix"],
    data() {
      return {
        searchValue: "",
        filterGroups: {}
      }
    },
    methods: {
      emitChanged() {
        this.$emit('filtered', this.filteredList )
      },
      setFilterGroups() {
        this.filterGroups =_.map(this.filterProperties, (flagProperty) => {
          const distinctValues = _.uniq(_.map(this.dataList, tour => _.get( tour, flagProperty)))
          const filters = {}
          _.each(distinctValues, (distinctValue, index) => {

            let filterEnabled = false
            if( this.storagePrefix && localStorage.getItem( this.storagePrefix + "-" + distinctValue ) !== null )
              filterEnabled = localStorage.getItem( this.storagePrefix + "-" + distinctValue ) === 'true'
            else if( distinctValue )
              filterEnabled = true

            filters[distinctValue] = {
              filter: (item) => _.get( item, flagProperty ) === distinctValue,
              enabled: filterEnabled,
              label: distinctValue || "Empty " + flagProperty
            }}
          )
          return filters
        })
      },
      onFilterChanged(filterGroup, key) {
        this.$set(filterGroup[key], "enabled", !filterGroup[key].enabled )
        if( this.storagePrefix )
          localStorage.setItem( this.storagePrefix + "-" + key, filterGroup[key].enabled )
        this.emitChanged()
      },
      searchedList(list, searchTokens) {
        if(!searchTokens)
          return list

        let tokens = searchTokens.toLowerCase()
        return _.filter( list, (item) => {
          return _.every( _.map( tokens.split(" "), (token) => {
            return _.some( this.searchProperties, (prop) => {
              const propValue = _.get( item, prop )
              return propValue && _.some( propValue.split(" "), splittedValue => splittedValue.toLowerCase().startsWith( token ) )
            } )
          } ) )
        } )
      },
    },
    computed: {
      filteredList() {
        let preselectedList = this.dataList
        _.each( this.filterGroups, (filterGroup) => {
          if( !_.isEmpty( filterGroup ) ) {
            preselectedList = _.filter( preselectedList, list => {
              return _.some( _.map(filterGroup, (filterFlag) => {
                return filterFlag.enabled && filterFlag.filter( list )
              } ) )
            } )
          }
        })
        return this.searchedList(preselectedList, this.searchValue) || []
      }
    },
    mounted() {
      this.setFilterGroups()
      this.emitChanged()
    },
    watch:{
      searchValue:"emitChanged"
    }
  }
</script>

<style scoped>

</style>
